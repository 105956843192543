import React from 'react';
import logo from './logo.svg';
import './App.css';
import Desktop from './screens/desktop';


function App() {
  return (
    
   <>
   <Desktop />
   </>

  );
}

export default App;
